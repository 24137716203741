import { getCmsBlocks } from '@core_modules/theme/services/graphql';
import CmsRenderer from '@core_modules/cms/components/cms-renderer';
import cx from 'classnames';

const CampaignLeft = (props) => {
    const { storeConfig } = props;

    const { data, loading, error } = getCmsBlocks({
        identifiers: 'campaign_floating_bottom_left',
    });

    const content = data?.cmsBlocks?.items[0]?.content;

    return (
        <>
            {content && !loading && !error && (
                <div
                    className={cx(
                        'swift-campaign-left left-5 fixed z-10 bottom-[87px] ',
                        'shadow-type-1 animate-anima-elastic leading-[0]',
                    )}
                >
                    <CmsRenderer content={content} storeConfig={storeConfig} />
                </div>
            )}
        </>
    );
};

export default CampaignLeft;
